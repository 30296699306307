import { currencies } from '@/models/Currencies'
import Decimal from 'decimal.js'
import { notify } from '@kyvg/vue3-notification'
import { getCurrencyCode } from '../models/Currencies'
import { SystemUsers } from '@/models/SystemUsers'

import router from '@/router'
import OfferModel from '@/models/requests/OfferModel'
import SocketOfferModel from '@/models/requests/SocketOfferModel'
import { getStateOffersName } from '@/Methods/OffersLists'
import MessageModel from '@/models/MessageModel'

const { currentRoute } = router

/**
 * Функция для возврата строки из экспоненциального числа
 * @param digits - число в экспоненциальном виде
 * @param amountScale - максимальное значение ограничения числа после запятой
 * @returns {*|string} - число в "человеческом" виде в виде строки
 */
const exportExponentialToString = (digits, amountScale) => {
  let exportString = ''

  let str = String(digits)
  let order = str.replace(/.+e-/gm, '')
  // eslint-disable-next-line
  let mantis = str.match(/(\d*[\.\,]?\d+?)e/)
  let mantisLength = 0
  if (mantis[1].indexOf('.') !== -1) {
    let mantisArray = mantis[1].split('.')
    mantisLength = mantisArray[1].length
  }
  let totalExponentialDigits = Number(order) + Number(mantisLength)
  if (totalExponentialDigits < amountScale) {
    exportString = digits.toFixed(totalExponentialDigits)
  } else {
    exportString = digits.toFixed(amountScale)
  }
  return exportString
}

/**
 * Функция перевода строки с экспоненциальной записью в обычныю
 * @param amount
 * @returns {string}
 */
// eslint-disable-next-line no-unused-vars
const convertAmount = amount => {
  let str = String(Number(amount))
  if (str.indexOf('e') !== -1) {
    let numb = str.replace(/.+e-/gm, '')
    amount = exportExponentialToString(Number(amount), Number(numb))
  }
  return String(amount)
}

const updateCurrenciesList = (context, currency) => {
  if (currency?.includes('Cash')) return
  const currencyShortUpperCase = currency ? getCurrencyCode(currency)?.toUpperCase() : ''
  let currencies = context.getters.getMainWalletCurrencies
  if (currencies.includes(currencyShortUpperCase)) return
  currencies.push(currencyShortUpperCase)
  context.commit('SetCurrencyList', currencies)
}

const actions = {
  NewTransferIncome (context, oneTransfer) {
    let transfer = ''
    if (typeof oneTransfer === 'string') {
      transfer = JSON.parse(oneTransfer)
    } else {
      transfer = oneTransfer
    }
    console.log('New transfer', transfer)
    let transactions = []
    if (typeof transfer['transaction_list'] !== 'undefined') {
      transactions = transfer.transaction_list?.transactions || []
    } else {
      transactions = transfer
    }
    var notified = false
    let countPayment = 0

    // старт рефакторинга этого метода
    // кассиры
    const userTransactions = transactions.filter(({ user_account_info }) => {
      return context.getters.userWalletsIds.includes(user_account_info.wallet_id)
    })
    let isCash = false
    userTransactions.forEach(tr => {
      const {
        input_output_type,
        amount,
        user_account_info: { wallet_id: walletId, account_id: accountId }
      } = tr
      if (amount.currency_type === 'MessageToken') {
        const message = MessageModel(tr)
        context.commit('updateMessagesObject', message)
        // костыль для показа badge в новых транзакциях
        countPayment--
        return
      }
      if (amount?.currency_type?.includes('Cash')) isCash = true
      const expressionMethod = input_output_type === 'INPUT' ? 'plus' : 'minus'
      const currency = context.rootState.tokensNameMap[amount.currency_type]?.code || amount.currency_type
      const oldValue = context.rootState.Transactions.balanceObject?.[walletId]?.[accountId]?.[currency] || 0
      const newValue = new Decimal(oldValue)[expressionMethod](amount.amount).toFixed()
      context.commit('updateWalletObjectItem', { walletId, accountId, currency, newValue })
      if (input_output_type === 'INPUT') {
        notify({
          title: 'Успех',
          text: `New incoming transfer`,
          type: 'success'
        })
      }
    })
    console.log({ userTransactions })

    transactions
      .filter(i => {
        return context.getters.userWalletsIds.includes(i.user_account_info.wallet_id)
      })
      .sort((a, b) => a.timestamp - b.timestamp)
      .forEach(transaction => { context.commit('updateUserTransactions', transaction) })
    if (currentRoute.value?.name !== 'statement') context.commit('ChangeNewPaymentCount', countPayment)
  },
  NewRequest (context, request) {
    let newRequest = JSON.parse(request.body)
    const socketOfferObj = new SocketOfferModel(newRequest)
    const objV2 = new OfferModel(socketOfferObj)
    context.commit('newRequestHandler', { offersName: getStateOffersName(socketOfferObj.request), data: objV2 })
    if (newRequest.type === 'In') {
      notify({
        title: 'Успех',
        text: 'New incoming request',
        type: 'success'
      })
      if (currentRoute.value?.name !== 'In request') context.commit('ChangeNewInRequest', 1)
    } else if (newRequest.type === 'Out') {
      context.dispatch('NewTransferIncome', newRequest)
      if (currentRoute.value?.name !== 'Out request') context.commit('ChangeNewOutRequest', 1)
    }
  },
  ChangeStatusRequest (context, data) {
    let request = JSON.parse(data.body)
    console.log('CHANGE STATUS', { request })
    if (['OfferAccepted', 'OfferRejected', 'OfferCanceled'].includes(request.offer_status)) {
      const offer = request.offer_records[0]
      offer.offer_status = request.offer_status
      context.commit('acceptedRejectedRequestHandler', offer)
      if (request.transactions) {
        context.dispatch('NewTransferIncome', request.transactions)
      }
    }
  }
}

export default {
  actions
}
