import axios from 'axios'
import Store from '@/store'
import { mainWallet } from '@/Methods/MainWalletFind'
import { createWallet } from '@/Methods/CreateWallet'
import { ExternalBitcoinWallet } from '@/Methods/ExternalBitcoinWallet'
import { ExternalEthWallet } from '@/Methods/ExternalEthWallet'
import { getCurrencyFullName } from '../models/Currencies'
import { parseUserWallets } from "@/utils/common";
import UserWalletModel from "@/models/UserWalletModel";
import UserWallletAccountModel from "@/models/UserWallletAccountModel";
import { createWalletApi, getWalletAccounts } from '@/api/wallets'

const state = () => ({
  userWallets: [],
  userWalletAccountsMap: {}
})

const mutations = {
  setUserWallets (state, payload) {
    state.userWallets = payload
  },
  setUserWalletAccountsMap (state, payload) {
    state.userWalletAccountsMap = payload
  },
}

const actions = {
  getWalletsAndAccounts: (context) => {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/wallets/accounts`
    })
      .then(response => {
        const wallets = []
        const walletAccountsMap = {}
        const walletList = response.data.payload.wallet_list
        walletList.forEach(item => {
          const newWallet = new UserWalletModel(item)
          wallets.push(newWallet)
          item.accounts.forEach(account => {
            if (walletAccountsMap[newWallet.walletId]) {
              walletAccountsMap[newWallet.walletId].push(new UserWallletAccountModel(account))
            } else {
              walletAccountsMap[newWallet.walletId] = [new UserWallletAccountModel(account)]
            }
          })
        })
        context.commit('setUserWallets', wallets)
        context.commit('setUserWalletAccountsMap', walletAccountsMap)
        const parsedWallets = parseUserWallets(walletList)
        // console.log({ parsedWallets, wallets, walletAccountsMap })
        return mainWallet(parsedWallets?.MAIN?.[0])
      })
  },
  getAllWalletsAndAccounts: (context) => {
    Store.commit('SetWalletsAndAccountFlag', false)
    return getWalletAccounts()
      .then(data => {
        return data.wallet_list
      })
  },
  createWallet: (context, dataForSend) => {
    const sendData = createWallet(dataForSend)
    return createWalletApi(sendData)
  },
  createBitcoinExternalWallet: (context, dataForSend) => {
    let sendData = ExternalBitcoinWallet(dataForSend)
    let fullCurrencyName = getCurrencyFullName(dataForSend.currency)
    return axios({
      method: 'POST',
      url: `${Store.state.backEndAddress}/wallets/accounts/${fullCurrencyName}`,
      data: sendData
    })
  },
  createEthExternalWallet: (context, dataForSend) => {
    let sendData = ExternalEthWallet(dataForSend)
    let fullCurrencyName = getCurrencyFullName(dataForSend.currency)
    return axios({
      method: 'POST',
      url: `${Store.state.backEndAddress}/wallets/accounts/${fullCurrencyName}/code`,
      data: sendData
    })
  }
}

const getters = {
  userWalletsIds: state => state.userWallets.map(item => item.walletId),
  userAccountsIdsByWalletId: state => walletId => state.userWalletAccountsMap[walletId].map(item => item.accountId),
  mainWalletId: state => state.userWallets.find(i => i.type === 'MAIN' && i.status === 'ACTIVE')?.walletId || '',
  getAccountIdByType: (state, getters) => accType => {
    return state.userWalletAccountsMap[getters.mainWalletId]?.find(i => i.accountType === accType && i.status === 'ACTIVE')?.accountId
  },
  mainAccountId: (state, getters) => {
    return getters.getAccountIdByType('MAIN')
  },
  mainFiatOutAccountId: (state, getters) => {
    return getters.getAccountIdByType('FIAT_OUT')
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
