import axios from 'axios'
import Store from '@/store'
import { FindOneProcess } from '@/Methods/FindOneProcess'
// import Store from '../store'

let defaultTruncatePrecision = 5

const state = () => ({
  operationLimitations: new Map(),
  constraints: {}
})

const mutations = {
  setOperationLimitations (state, value) {
    state.operationLimitations = value
  },
  setConstraints (state, value) {
    state.constraints = value
  }
}

const actions = {
  // TODO нужен рефакторинг с записью всех лимитов в state
  // TODO и использованием через getters
  fetchOperationLimitations ({ commit, rootState }, process) {
    return axios({
      method: 'GET',
      url: `${rootState.backEndAddress}/currencies/constraints`
    })
      .then(response => {
        if (response.data.status === 200) {
          const { process_currency_constraint_list } = response.data.payload
          const processTypesConstraints = {}
          process_currency_constraint_list.forEach(item => {
            const { process_type, currency_constraint_list } = item
            currency_constraint_list.forEach(currencyObj => {
              const currencyCode = rootState.tokensNameMap[currencyObj.currency_type]?.code || currencyObj.currency_type
              if (!processTypesConstraints[process_type]) processTypesConstraints[process_type] = {}
              processTypesConstraints[process_type][currencyCode] = currencyObj
              processTypesConstraints[process_type][currencyCode].process_type = process_type
              processTypesConstraints[process_type][currencyCode].is_enabled = currencyObj.is_enabled === 'true'
            })
          })
          commit('setConstraints', processTypesConstraints)
          if (!process) return response.data.payload.process_currency_constraint_list
          return FindOneProcess(response.data.payload.process_currency_constraint_list, process)
        } else {
          throw new Error('err')
        }
      })
      .then(response => {
        const operationLimitations = new Map()
        for (const item of response) {
          operationLimitations.set(rootState.tokensNameMap[item.currency_type]?.code || item.currency_type, item)
        }
        operationLimitations.set(undefined, defaultTruncatePrecision)
        commit('setOperationLimitations', operationLimitations)
        return response
      })
      .catch(err => {
        console.log({ err })
        throw new Error(err)
        // return err
      })
  },
  getOperationLimitations (context, process) {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/currencies/constraints`
    })
      .then(response => {
        if (!process) return response.data.payload.process_currency_constraint_list
        if (response.data.status === 200) {
          return FindOneProcess(response.data.payload.process_currency_constraint_list, process)
        } else {
          throw new Error('err')
        }
      })
      .then(response => {
        return response
      })
      .catch(err => {
        return err
      })
  },
  getList (context) {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/currencies`
    })
      .then(response => {
        if (response.data.status === 200) {
          const tokenTypes = response.data.payload.reduce((acc, i) => {
            acc[i.token_type] = i.token_type
            return acc
          }, {})
          console.log({ tokenTypes })
          return response.data.payload
        } else {
          throw new Error('err')
        }
      })
  }
}

const getters = {
  constraintProcesses: state => Object.keys(state.constraints),
  getProcessCurrencies: (state, getters, rootState) => process => {
    return Object.values(state.constraints[process] || {}).map(item => {
      return { title: rootState.tokensNameMap[item.currency_type].code, value: item.currency_type }
    })
  },
  getProcessObj: state => process => state.constraints?.[process] || {},
  getProcessCurrencyObj: state => (process, currency) => state.constraints?.[process]?.[currency]
}

export default {
  state,
  mutations,
  actions,
  getters
}
